<template>
    <div style="width: 100%; height: auto">
        <div class="shopStoreTitle">{{storeName}}<span class="tag">官方旗舰店</span></div>

        <div class="shopStoreBackend">
            <el-image v-if="backgroundImage" :src="imageURL + backgroundImage" class="image" fit="cover"></el-image>
            <el-image v-if="!isRequestLoading && !backgroundImage" src="./img/store_backend.png" class="image" fit="cover"></el-image>
        </div>

        <div class="shopStoreHead">
            <div class="container">
                <div class="content">
                    <a href="javascript:void(0)" class="title" :class="{on: type === 1}" @click="type === 1 ? '' : $router.replace({path: '/shop/store', query: {id: $route.query.id}})">店铺首页</a>

                    <div class="type">
                        <div class="btn" :class="{on: type === 2}">
                            <span>全部分类</span>
                            <img src="../../../assets/img/store_type_down.png" class="down" />
                            <img src="../../../assets/img/store_type_up.png" class="up" />
                        </div>

                        <div class="group">
                            <div class="list">
                                <a href="javascript:void(0)" @click="toChangeType('')">查看全部分类</a>
                                <a href="javascript:void(0)" v-for="(item, index) in categoryList" :key="index" @click="toChangeType(item)">{{item}}</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="search">
                    <el-input v-model="keyword" placeholder="请输入搜索内容" @keyup.enter.native="toSearch"></el-input>
                    <el-button type="primary" @click="toSearch" icon="el-icon-search"></el-button>
                </div>
            </div>
        </div>

        <div class="shopStoreType" v-if="type === 2">
            <div class="content">
                <span class="title">商品分类：</span>

                <div class="list">
                    <a href="javascript:void(0)" :class="{on: storeGoodsType === ''}" @click="toChangeType('')">全部</a>
                    <a href="javascript:void(0)" v-for="(item, index) in categoryList" :key="index" :class="{on: storeGoodsType === item}" @click="toChangeType(item)">{{item}}</a>
                </div>
            </div>
        </div>

        <div class="shopStoreSort" v-if="type === 3">
            <div class="content">
                <ul>
                    <li :class="listQuery.desc === '0' ? 'on' : ''"><a href="javascript:void(0)" @click="toChangeDesc('0')"><span>默认排序</span></a></li>
                    <li :class="listQuery.desc === '1' ? 'on' : ''"><a href="javascript:void(0)" @click="toChangeDesc('1')"><span>成交量</span></a></li>
                    <li :class="listQuery.desc === '2' || listQuery.desc === '3' ? 'on' : ''" @click="toChangeDesc('2')">
                        <a href="javascript:void(0)">
                            <span>价格</span>
                            <div class="icon">
                                <i :class="listQuery.desc === '3' ? 'el-icon-caret-top on' : 'el-icon-caret-top'"></i>
                                <i :class="listQuery.desc === '2' ? 'el-icon-caret-bottom on' : 'el-icon-caret-bottom'"></i>
                            </div>
                        </a>
                    </li>
                </ul>

                <div class="price">
                    <div class="submit">
                        <el-button type="text" @click="listQuery.startPrice = '', listQuery.endPrice = ''">清 空</el-button>
                        <el-button type="primary" @click="toChangePrice">确 定</el-button>
                    </div>

                    <div class="popupContent">
                        <el-input v-model.number="listQuery.startPrice" @keyup.enter.native="toChangePrice"> <template slot="prepend">¥</template></el-input>

                        <span class="line">-</span>

                        <el-input v-model.number="listQuery.endPrice" @keyup.enter.native="toChangePrice"> <template slot="prepend">¥</template></el-input>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {imageURL} from "../../../utils/config";

  export default {
    inject: ['reload'],
    name: "shopStoreHeader",
    data () {
      return {
        imageURL: imageURL,
        keyword: this.type === 3 ? this.$route.query.keyword : '',
        listQuery: {
          desc: this.type === 3 ? this.$route.query.desc : '0',
          startPrice: this.type === 3 ? this.$route.query.startPrice : '',
          endPrice: this.type === 3 ? this.$route.query.endPrice : ''
        }
      }
    },
    props: {
      type: {
        type: Number,
        default: 1, // 1-首页，2-分类，3-搜搜
      },
      storeName: {
        type: String,
        default: ''
      },
      backgroundImage: {
        type: String,
        default: ''
      },
      storeGoodsType: {
        type: String,
        default: ''
      },
      categoryList: {
        type: Array,
        default: () => {
          return []
        }
      },
      isRequestLoading: {
        type: Boolean,
        default: false
      }
    },
    created () {
    },
    methods: {
      toSearch () {
        const { href } = this.$router.resolve({path: '/shop/store/search', query: {id: this.$route.query.id, keyword: this.keyword, desc: this.listQuery.desc, startPrice: this.listQuery.startPrice, endPrice: this.listQuery.endPrice}});
        window.location.href = href;
        this.reload();
      },
      toChangeType (name) {
        const { href } = this.$router.resolve({path: '/shop/store/type', query: {id: this.$route.query.id, type: name}});
        window.location.href = href;
        this.reload();
      },
      toChangeDesc (val) {
        if (val === '2' && this.listQuery.desc === '2') {
          this.listQuery.desc = '3'
        } else {
          this.listQuery.desc = val
        }
        const { href } = this.$router.resolve({path: '/shop/store/search', query: {id: this.$route.query.id, keyword: this.keyword, desc: this.listQuery.desc, startPrice: this.listQuery.startPrice, endPrice: this.listQuery.endPrice}});
        window.location.href = href;
        this.reload();
      },
      toChangePrice () {
        const { href } = this.$router.resolve({path: '/shop/store/search', query: {id: this.$route.query.id, keyword: this.keyword, desc: this.listQuery.desc, startPrice: this.listQuery.startPrice, endPrice: this.listQuery.endPrice}});
        window.location.href = href;
        this.reload();
      }
    },
    components: {}
  }
</script>

<style lang="scss" scoped>
    @import "../../../assets/style/store";
</style>
